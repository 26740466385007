import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Icon from '../_shared/Icon';
import { reverseDate } from "../../commons/functions";
import DownloadLink from '../_shared/DownloadLink';

const API_URL = process.env.REACT_APP_API_URL;

const CompetitionItem = ({ editionData }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const openHandler = () => {
    setOpen(prevState => !prevState);
  }

  return (
      <div>
        <div className={`relative mb-6 md:mb-4 text-lg text-xl ${open ? 'font-nouvelrbold md:font-nouvelr' : ''}`}>
            <span className="block lg:inline">{editionData.editionName}</span>
          <div className="flex justify-between items-center">
            <span className="block">{reverseDate(editionData.dateFrom)} — {reverseDate(editionData.dateTo)}</span>
            <Icon  id="upArrBold" viewBox="0 0 32 32" width="32" height="32" clsWrap={`hover:cursor-pointer ${open ? '' : 'rotate-180'}`} onClickFnc={() => openHandler()} />
          </div>
        </div>
        <div className={`${open ? 'block mb-4' : 'hidden'}`}>
          <div className={'block md:flex items-center justify-between mb-6 md:mb-4'}>
            <div className="flex item-center justify-between md:block">
              <span className="block text-base font-semibold">{t('workshopTurnover')}</span>
              <span className="block text-xl">{editionData.turnoverFormatted}</span>
            </div>
            <div className="flex item-center justify-between md:block mt-3 md:mt-0">
              <span className="block text-base font-semibold">{t('positionInTheCompetition')}</span>
              <span className="block text-xl">{editionData.position}</span>
            </div>
            <div className="flex item-center justify-between md:block mt-3 md:mt-0">
              <span className="block text-base font-semibold">{editionData.competitionId === 'points' ? t('PointsEarned') : t('prize')}</span>
              <span className="block text-xl">{editionData.competitionId === 'points' ? editionData.score : editionData.prize}</span>
            </div>
          </div>
          <div>
            {_.map(editionData.files, file => (
              <DownloadLink text={file.name} link={`${API_URL}${file.url}`} />
            ))}
          </div>
        </div>
      </div>
  )
}

const CompetitionPrev = ({ otherEditions }) => {
  const { t } = useTranslation();
  let editions;

  if (otherEditions) {
      editions = otherEditions.map(edition => <CompetitionItem editionData={edition} key={edition.editionId} />);
  }

  return (
      <div className="lg:relative lg:z-10 lg:w-2/5 lg:pr-12 lg:bg-white">
        {otherEditions && otherEditions.length > 0 &&
          <h3 className="mt-10 mb-6 font-semibold text-3xl md:text-30px">{t('previousEditions')}</h3>
        }
        {editions}
      </div>
  )
}

export default CompetitionPrev;