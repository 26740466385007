import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {pdfs} from '../../commons/functions';
import DownloadLink from "../_shared/DownloadLink";
import Icon from '../_shared/Icon';
import JwtInterceptor from '../_shared/JwtInterceptors';
import AuthContext from "../_shared/AuthContext";
import _ from "lodash";

const API_URL = process.env.REACT_APP_API_URL;

const RankingRow = ({ competitionType, data, missingFirstPlaceFormatted, missingNextTresholdFormatted }) => {
  const { t } = useTranslation();
  const s = data.serviceName.substring(0, 8);

  return (
      <tr className={s === "Warsztat" ? '' : 'bg-black text-white font-semibold'}>
        <td className="px-2 py-4 text-center align-middle">{data.position}</td>
        <td className="px-2 py-4 align-middle">
          {s !== "Warsztat" &&
              <span className="block mb-1 font-light text-xs">{t('yourWorkshop')}</span>
          }
          {data.serviceName}</td>
        <td className="px-2 py-4 text-center align-middle whitespace-nowrap	">{data.turnoverFormatted}</td>
        {s !== "Warsztat" ?
          <td className="px-2 py-4 text-center align-middle whitespace-nowrap	">{missingFirstPlaceFormatted ? missingFirstPlaceFormatted : missingNextTresholdFormatted}</td>
        :
          <td className="px-2 py-4 text-center align-middle whitespace-nowrap	">-</td>
        }
        {competitionType === 'materialPrize' ?
            <td className="px-2 py-4 text-center align-middle">{data.score === 1 ? <Icon id={s === "Warsztat" ? 'prize' : 'prizeWhite'} viewBox="0 0 25 24" width="25" height="24" clsWrap="fill-white relative left-6" /> : ''}</td>
        :
          <td className="px-2 py-4 text-center align-middle whitespace-nowrap	">{data.prizeFormatted}</td>
        }
      </tr>
  );
}

const RankingPointsRow = ({ data }) => {
  const { t } = useTranslation();
  const s = data.serviceName.substring(0, 8);

  return (
      <tr className={s === "Warsztat" ? '' : 'bg-black text-white font-semibold'}>
        <td className="px-2 py-4 text-center align-middle">{data.position}</td>
        <td className="px-2 py-4 align-middle">
          {s !== "Warsztat" &&
            <span className="block mb-1 font-light text-xs">{t('yourWorkshop')}</span>
          }
          {data.serviceName}</td>
        <td className="px-2 py-4 text-center align-middle whitespace-nowrap	">{data.turnoverFormatted}</td>
        <td className="px-2 py-4 text-center align-middle whitespace-nowrap	">{data.score}</td>
        <td className="px-2 py-4 text-center align-middle">{data.prizeFormatted ? <Icon id={s === "Warsztat" ? 'prize' : 'prizeWhite'} viewBox="0 0 25 24" width="25" height="24" clsWrap="fill-white relative left-6" /> : ''}</td>
      </tr>
  );
}

const CompetitionText = ({ competitionType, missingFirstPlaceFormatted, missingNextTresholdFormatted, files }) => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [points, setPoints] = useState([]);
  let pointsRow = [];

  useEffect(() => {
    window.scrollTo(0, 0);

    JwtInterceptor
        .get(`${API_URL}Competitions/user/${competitionType}`)
        .then((response) => {
          setPoints(response.data.ranking);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (competitionType === "points") {
    pointsRow = points.map(pointRow => <RankingPointsRow data={pointRow} key={pointRow.position} />);
  } else {
    pointsRow = points.map(pointRow => <RankingRow competitionType={competitionType} data={pointRow} missingFirstPlaceFormatted={missingFirstPlaceFormatted} missingNextTresholdFormatted={missingNextTresholdFormatted} key={pointRow.position} />);
  }

  return (
      <div className="lg:w-3/5 mb-11 lg:mb-0">
        <h3 className="mt-10 mb-6 font-semibold text-3xl md:text-30px">O konkursie</h3>
        {/*<p className="text-xl">Wygraj niezawodne narzędzie do swojego warsztatu - Klucz udarowy DeWALT DC899P2-QW. Zdobądź jak największą liczbę punktów generując największy obrót i największy procentowy wzrost obrotu.</p>*/}
        {competitionType === 'points' &&
          <div className="text-xl" dangerouslySetInnerHTML={{__html: t(`competitionCatchPoints`) }} />
        }
        {competitionType === 'materialPrize' &&
          <div className="text-xl" dangerouslySetInnerHTML={{__html: t(`competitionBody`) }} />
        }
        {competitionType === 'materialPrizeCatalog' &&
          <div className="text-xl" dangerouslySetInnerHTML={{__html: t(`competitionWinPrizes`) }} />
        }
        <h3 className="mt-20 mb-6 font-semibold text-3xl md:text-30px">Ranking aktualnego konkursu</h3>
        <div className="overflow-y-auto md:overflow-y-hidden">
          <table className="text-base">
            <thead>
              {competitionType === "points" ?
                <tr className="font-semibold text-left">
                  <th className="px-2 py-5 align-middle">{t('place')}</th>
                  <th className="px-2 py-5 align-middle">{t('participant')}</th>
                  <th className="px-2 py-5 align-middle text-center">{t('turnoverRPartStore')}</th>
                  <th className="px-2 py-5 align-middle text-center">{t('turnoverPoints')}</th>
                  <th className="px-2 py-5 align-middle">{t('prize')}</th>
                </tr>
              :
                <tr className="font-semibold text-left">
                  <th className="px-2 py-5 align-middle">{t('place')}</th>
                  <th className="px-2 py-5 align-middle">{t('participant')}</th>
                  <th className="px-2 py-5 align-middle text-center">{t('yourResult')}</th>
                  {competitionType === 'materialPrize' ?
                    <th className="px-2 py-5 align-middle text-center">{missingFirstPlaceFormatted ? t('missingFirstPlace') : t('missingToPrize')}</th>
                  :
                    <th className="px-2 py-5 align-middle text-center">{missingFirstPlaceFormatted ? t('missingFirstPlace') : t('missingNextTreshold')}</th>
                  }
                  <th className="px-2 py-5 align-middle">{t('prize')}</th>
                </tr>
              }
            </thead>
            <tbody>
              {pointsRow}
            </tbody>
          </table>
        </div>
        <h3 className="mt-20 mb-6 font-semibold text-3xl md:text-30px">Regulamin konkursu</h3>
        <p className="text-xl mb-8">Prosimy o zapoznanie się z regulaminem konkursu, który zawiera ważne informacje dotyczące zasad, nagród i warunków uczestnictwa. Regulamin dostępny jest do pobrania poniżej. Przeczytaj go uważnie, abyś był/a dobrze poinformowany/a na temat wszystkich szczegółów związanych z konkursem. Życzymy powodzenia!</p>
        {_.map(files, file => (
            <DownloadLink text={`Pobierz ${file.name.toLowerCase()}`} link={`${API_URL}${file.url}`} />
        ))}
      </div>
  )
}

export default CompetitionText;